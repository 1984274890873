import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const P: React.FC<{ noBottomMargin?: boolean; endOfSection?: boolean }> = ({
  noBottomMargin,
  endOfSection,
  children,
}) => (
  <p className={endOfSection ? 'mb-17' : !noBottomMargin ? 'mb-4' : ''}>
    {children}
  </p>
)

const Disclaimer: React.FC = () => (
  <Layout>
    <SEO title='Disclaimer' />
    <div className='py-8 md:mx-24 md:max-w-148 leading-loose'>
      <h1 className='text-4xl font-bold mb-8'>Disclaimer</h1>
      <P>
        The information contained on this website (hereinafter the
        "Information") is provided "as is" and has been compiled by Venture
        Friends 3 Management B.V. (hereinafter "Venture Friends") from sources
        believed to be reliable. The Information is made available without
        responsibility on the part of Venture Friends who neither assumes nor
        accepts any responsibility or liability (including for negligence) in
        relation thereto.
      </P>
      <P>
        The Information contained or referred to may change or be updated
        without notice. Use of the Information is at the sole risk of the user.
        The Information (i) does not constitute an offer to sell or a
        solicitation of an offer to buy an interest in any fund managed by
        Venture Friends or any of its affiliates; (ii) is not, and under no
        circumstances is it to be construed as a prospectus, as marketing or as
        an advertisement; and (iii) is not intended for distribution to or use
        by any person or entity in any jurisdiction or country where such
        distribution would be contrary to local law or regulation.
      </P>
      <P>
        Venture Friends is exempted from the requirement to obtain a license and
        is registered with the Dutch financial regulator, the Stichting
        Autoriteit Financiële Markten ("AFM") under the so-called de minimis
        regime. Venture Friends is therefore not subject to supervision by the
        AFM or by the Dutch Central Bank and is not under an obligation to issue
        a prospectus.
      </P>
      <div className='my-4 flex space-x-6'>
        <a
          className='text-primary hover:text-secondary'
          href='https://drive.google.com/drive/folders/1u4a0vojTo6UtcfE-fqpcarvVh5GZc9ce?usp=sharing'
          target='_blank'
          rel='noreferrer noopener'
        >
          KID
        </a>
        <a
          className='text-primary hover:text-secondary'
          href='https://drive.google.com/drive/folders/1ARTv6GfA3ytyLDkZAoZ99KC1ySuDY8yA?usp=sharing'
          target='_blank'
          rel='noreferrer noopener'
        >
          Disclaimer
        </a>
      </div>
    </div>
  </Layout>
)

export default Disclaimer
